<script>
import Loadering from "./loadering";
import { mailService } from "@/helpers/mailService/mail.service";
import { format } from "date-fns"; //formatea la fecha eeee nombre y d dia
import { es } from "date-fns/locale"; //formatea la fecha en español.
/**
 * Email-sent component
 */
export default {
  components: {
    Loadering,
  },
  data() {
    //data[fecha]
    return {
      isActive: "semana",
      series: [],
      categories: [],
      chartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Deciembre",
          ],
        },
        colors: ["#556ee6", "#f1b44c", "#34c38f"],
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  props: {
    updating: {
      type: Boolean,
    },
  },
  mounted() {
    this.changeVal("semana");
  },
  methods: {
    changeVal(value) {
      if (value == "semana") {
        let params = {
          option: "semana",
        };
        mailService
          .getStats(params)
          .then((response) => {
            if (response.status == "success") {
              let responseData = response.data;
              let enviados = [];
              let rechazados = [];
              let sincronizados = [];
              let categories = [];
              Object.keys(responseData).forEach((date) => {
                let dataForDate = responseData[date][0];
                enviados.push(dataForDate.total_enviados);
                rechazados.push(dataForDate.total_rechazados);
                sincronizados.push(dataForDate.total_sincronizados);
                // Formatear la fecha
                let formattedDate = format(new Date(date), "eeee d 'de' MMMM", {
                  locale: es,
                });
                categories.push(formattedDate);
              });
              /* console.log(enviados); */
              let seriesData = [
                {
                  name: "Correos Enviados",
                  data: enviados,
                },
                {
                  name: "Correos Rechazados",
                  data: rechazados,
                },
                {
                  name: "Correos Sincronizados",
                  data: sincronizados,
                },
              ];
              this.series = seriesData;
              this.categories = categories; // VALORES FORMATEADOS
              this.isActive = "semana";
            }
          })
          .catch((error) => {
            console.log("Error al obtener datos: ", error);
          });
      }
    },
  },
};
</script>
<template>
  <Loadering loading="updating">
    <div class="card">
      <div class="card-body">
        <div class="d-sm-flex flex-wrap">
          <h4 class="card-title mb-4">Estadisticas de Correo</h4>
          <div class="ms-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="javascript: void(0);"
                  @click="changeVal('semana')"
                  :class="{ active: isActive == 'semana' }"
                  >Semana</a
                >
              </li>
            </ul>
          </div>
        </div>

        <apexchart
          class="apex-charts"
          type="bar"
          dir="ltr"
          height="360"
          :series="series"
          :options="{ chartOptions, xaxis: { categories: categories } }"
        ></apexchart>
      </div>
    </div>
  </Loadering>
</template>
